@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  @apply bg-light-bg text-light-text;
}

body.dark {
  @apply bg-dark-bg text-dark-text;
}
