@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px; 
  font-weight: 400;
  font-style: normal;
  
  background-color: #ffffff;
  color: #000000;
  transition: background-color 0.3s, color 0.3s;
}

body.light {
  background-color: #ffffff;
  color: #000000;
}

body.dark {
  background-color: #333333;
  color: #ffffff;

}


.nav Typography {
  background-color: #f0f0f0;
  color: #000000;
  transition: background-color 0.3s, color 0.3s;
}

body.dark .nav body.dark Typography {
  background-color: #444444;
  color: #ffffff;
}


.sidebar {
  background-color: #f8f9fa;
  color: #000000;
}

body.dark .sidebar {
  background-color: #212121;
  color: #ffffff;
}


.icon {
  color: #000000;
}

body.dark .icon {
  color: #ffffff;
}





form {
  background-color: #f9f9f9;
  color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

body.dark form {
  background-color: #424242;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

body.light .showapply {
  background-color: #f9f9f9;
  color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

body.dark .showapply {
  background-color: #424242;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

input,
select,
textarea,
checkbox,
.calendar {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #cccccc;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}

body.dark input,
body.dark select,
body.dark textarea,
body.dark checkbox,
body.dark .calendar {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #555555;
}

label {
  color: #000000;
  transition: color 0.3s;
}

body.dark label {
  color: #ffffff;
}

h1 {
  font-size: 18px; 
  font-weight: 700;
  font-style: bold;
}

h2, h3, h4 {
  font-size: 16px; 
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3a1616;
  background-color: #f0f0f0;
  transition: color 0.3s, background-color 0.3s;
}

body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5,
body.dark h6 {
  color: #ffffff;
  background-color: #252424;
}

button {

  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}




@keyframes submitColorChange {
  0% {
    background-color: #1e3a8a;   
  }

  33% {
    background-color: #60a5fa;
  }

  66% {
    background-color: #2563eb;
  }

  100% {
    background-color: #1e3a8a;
  }
}

.hover\:animate-submitColorChange:hover {
  animation: submitColorChange 1.5s infinite;
}

@keyframes resetColorChange {
  0% {
    background-color: #1f2937;
  }

  33% {
    background-color: #9ca3af;
  }

  66% {
    background-color: #6b7280;
  }

  100% {
    background-color: #1f2937;
  }
}

.hover\:animate-resetColorChange:hover {
  animation: resetColorChange 1.5s infinite;
}



button:disabled,
.btn-dis[disabled],
.btn-dis:disabled button[disabled],
input[type="checkbox"]:disabled {
  border: 1px solid #999999;
  background-color: darkgrey !important;
  color: #fff;
  padding: 10px;
  cursor: not-allowed;
  position: relative;
}


button:disabled:hover::after,
.btn-dis:disabled:hover::after,
input[type="checkbox"]:disabled:hover::after {
 
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  bottom: 100%;
  left: 50%;

  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0.9;
  font-size: 12px;
  pointer-events: none;
}

.btn {
  border: 1px solid #999999;
  background-color: blue;
  color: #fff;
  padding: 10px;
}

.btn-enabled {
  background-color: transparent !important;
   cursor: not-allowed;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes hoverEffect {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.02);
  }
}




table {
  border-collapse: collapse;
  width: 100%;
}





.apexcharts-toolbar {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.apexcharts-toolbar .apexcharts-menu {
  background-color: transparent !important;
}

.apexcharts-toolbar .apexcharts-button {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 5px;
  transition: background-color 0.3s, color 0.3s;
}


.dark .apexcharts-toolbar .apexcharts-button {
  background-color: #333 !important;
  color: #fff !important;
  border: 1px solid #444 !important;
}

.dark .apexcharts-toolbar .apexcharts-button:hover {
  background-color: #444 !important;
  color: #fff !important;
}


.light .apexcharts-toolbar .apexcharts-button {
  background-color: #fff !important;
  color: #333 !important;
  border: 1px solid #ccc !important;
}

.light .apexcharts-toolbar .apexcharts-button:hover {
  background-color: #f1f1f1 !important;
  color: #333 !important;
}



.apexcharts-tooltip {
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}


.dark .apexcharts-tooltip {
  background-color: #333 !important; 
  color: #fff !important;           
  border: 1px solid #444 !important; 
}


.light .apexcharts-tooltip {
  background-color: #fff !important; 
  color: #333 !important;            
  border: 1px solid #ccc !important; 
}

.dark .apexcharts-tooltip.custom-tooltip {
  background-color: #444 !important;  
  color: #fff !important;             
  padding: 5px;
  font-weight: bold;
}

.light .apexcharts-tooltip.custom-tooltip {
  background-color: #f1f1f1 !important; 
  color: #000 !important;              
  padding: 5px;
  font-weight: bold;
}


body.light .apexcharts-tooltip {
  background-color: #fff !important;  
  color: #333 !important;             
  border: 1px solid #ccc !important; 
}


body.dark .apexcharts-tooltip {
  background-color: #333 !important; 
  color: #fff !important;             
  border: 1px solid #444 !important;  
}


body.light .apexcharts-tooltip .custom-tooltip-header {
  background-color: #f1f1f1 !important;  
  color: #000 !important;                
  font-weight: bold !important;
  padding: 5px !important;
}

body.dark .apexcharts-tooltip .custom-tooltip-header {
  background-color: #444 !important;  
  color: #fff !important;           
  font-weight: bold !important;
  padding: 5px !important;
}


body.light .apexcharts-tooltip .custom-tooltip-content,body.light .apexcharts-tooltip-title {
  background-color: #fff !important; 
  color: #333 !important;             
  padding: 5px !important;
}

body.dark .apexcharts-tooltip .custom-tooltip-content,body.dark .apexcharts-tooltip-title {
  background-color: #333 !important; 
  color: #fff !important;             
  padding: 5px !important;
}


.tooltip-header-dark {
  background-color: #333 !important; 
  color: #fff !important;
  padding: 5px;
  font-weight: bold;
  border-radius: 5px;
}

.tooltip-content-dark {
  background-color: #444 !important;
  color: #fff !important;
  padding: 5px;
  border-radius: 5px;
}


.tooltip-header-light,.apexcharts-tooltip-title {
  background-color: #f1f1f1 !important;
  color: #333 !important;
  padding: 5px;
  font-weight: bold;
  border-radius: 5px;
}

.tooltip-content-light {
  background-color: #fff !important;
  color: #333 !important;
  padding: 5px;
  border-radius: 5px;
}


.nivo-tooltip {
  background-color: #333 !important;
  color: #fff !important;           
  border-radius: 4px !important;   
  padding: 8px 12px !important;     
  font-size: 14px !important;        
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important; 
  font-family: Arial, sans-serif;   
}

.nivo-tooltip .name {
  font-weight: bold !important;     
}

.nivo-tooltip .value {
  color: #f39c12 !important;         
}

.nivo-tooltip {
  background-color: #333 !important;
  color: #fff !important;
  padding: 10px !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
  font-size: 14px !important;
  font-family: Arial, sans-serif !important;
}

.nivo-tooltip .nivo-tooltip__name {
  font-weight: bold !important;
}

.nivo-tooltip .nivo-tooltip__value {
  color: #f39c12 !important;
}


body.light table thead th:hover,table thead th:hover {
  background-color: rgba(0, 0, 0, 0.1); 
}


body.dark table thead th:hover {
  background-color: rgba(255, 255, 255, 0.1); 
}


body.light table tbody tr:hover,table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.1); 
}


body.dark table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1); 
}


body.dark table tbody td:hover,
body.light table tbody td:hover,table tbody td:hover {
  background-color: inherit; 
}


body.light table thead th:hover,table thead th:hover {
  background-color: rgba(0, 0, 0, 0.1); 
}


body.dark table thead th:hover {
  background-color: rgba(255, 255, 255, 0.1); 
}
